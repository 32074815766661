import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/ecco-diva/16(1).jpg"
import img2 from "../../images/ecco-diva/16(2).jpg"
import img3 from "../../images/ecco-diva/16(3).jpg"

import descImg1 from "../../images/ecco-diva/features.png"
import descImg2 from "../../images/ecco-diva/purpose.png"

const EccoDivaPage = () => {
  return (
    <Layout home={false}>
      <SEO title="Ecco Diva" />
      <ProductPage
        productTitle="Ecco Diva"
        showcase={
          <>
            <p style={{ lineHeight: "1.8rem" }}>
              High street lighting Ecco Diva is one the most trusted products.
              It provides a cost effective solution with a 5 hour continuous
              usage of high level light in comparison to the Petromax or a 9W
              CFL lamp.
            </p>
            <p style={{ lineHeight: "1.8rem" }}>
              Ecco Diva uses sunlight to charge its batteries through a solar
              panel to provide light in night time and a power backup to charge
              various portable devices.
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img1, img2, img3]}
        description={
          <div style={{ width: "100%" }}>
            <ul>
              <li style={{ fontSize: "1.2rem" }}>Solar Ready</li>
              <li style={{ fontSize: "1.2rem" }}>
                Battery back-up of 10 Hours in the highest Brightness mode, and
                a 250 hour Battery back-up in the Night-Lamp Mode.
              </li>
              <li style={{ fontSize: "1.2rem" }}>
                Faster Charging (Full charge in 4 Hours with Mains Adaptor)
              </li>
              <li style={{ fontSize: "1.2rem" }}>Energy Efficiency</li>
              <li style={{ fontSize: "1.2rem" }}>
                Micro-Controller based design delivers High Charging Efficiency
                & LED drive
              </li>
              <li style={{ fontSize: "1.2rem" }}>
                Maximum Power Point Tracking (MPPT) for efficient solar charging
              </li>
              <li style={{ fontSize: "1.2rem" }}>
                Overcharge and Deep Discharge Protection
              </li>
              <li>High performance Lithium Ion battery</li>
              <li style={{ fontSize: "1.2rem" }}>
                Flexi- Charging With both Solar and Mains
              </li>
              <li style={{ fontSize: "1.2rem" }}>
                Power Pack for powering up and charging Mobile Phones, tablets,
                iPad and other USB devices, Satellite Phones, Wireless Radios,
                GPS and other devices.
              </li>
              <li style={{ fontSize: "1.2rem" }}>4 Brightness Levels</li>
              <li style={{ fontSize: "1.2rem" }}>
                Provides a LUX of 800 at a distance of 1 foot in its brightest
                mode
              </li>
              <li style={{ fontSize: "1.2rem" }}>
                Longer backup time with User Interface for battery health
                indicator
              </li>
              <li style={{ fontSize: "1.2rem" }}>Multiple Charging options</li>
              <li style={{ fontSize: "1.2rem" }}>
                Solar Panel Grid electricity (90V-270V AC) Dynamo, Car Charger
              </li>
              <li style={{ fontSize: "1.2rem" }}>Home lighting</li>
            </ul>
            <div className="product-description-heading">Key Features</div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={descImg1} style={{ maxWidth: "80%", maxHeight: "25rem" }} />
            </div>
            <div className="product-description-heading">
              Ecco Diva Multi-Purpose
            </div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={descImg2} style={{ maxWidth: "80%", maxHeight: "40rem" }} />
            </div>
          </div>
        }
      />
    </Layout>
  )
}

export default EccoDivaPage
